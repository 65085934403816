<template lang="pug">
    v-container#dashboard-core-footer.footer-core
      //- Login
      v-dialog(max-width='380' origin v-model="loginDialog")
        login(userType="bussiness"  @close="loginDialog = false" @switchDialog="switchDialog('register')")
      //- Register
      v-dialog(  origin  v-model="registerDialog")
        register(userType="bussiness"  @close="registerDialog = false" @switchDialog="switchDialog('login')")
      div(v-if="!isMobile")
        v-row
          v-col(cols="3")
            h3 GET TO KNOW US
            v-col.pa-0.footer-core__sections
              a.mr-0.grey--text.text--darken-3(href="#/contributor/about-us" v-text="'About'")
              a.m-0.grey--text.text--darken-3( href="#/contributor/values" v-text="'Values'")
              a.m-0.grey--text.text--darken-3(href="#/contributor/careers" v-text="'Careers'")
              a.m-0.grey--text.text--darken-3(href="#/contributor/mission" v-text="'Mission statement'")
              a.m-0.grey--text.text--darken-3( href="#/contributor/news" v-text="'News'")

          v-col(cols="3")
            h3 OUR POLICIES
            v-col.pa-0.footer-core__information
              a.mr-0.grey--text.text--darken-3(href="#/contributor/terms-and-conditions" v-text="'Privacy policy'")
              a.m-0.grey--text.text--darken-3(href="#/contributor/terms-and-conditions" v-text="'T&C'")
              a.m-0.grey--text.text--darken-3(href="#/contributor/terms-and-conditions" v-text="'Authorization to release'")
              a.m-0.grey--text.text--darken-3(href="#/contributor/terms-and-conditions" v-text="'Refund Policy'")
          v-col(cols="3")
            h3 CONTACT US
            v-col.pa-0.footer-core__information
              a.m-0.grey--text.text--darken-3(href="#/contributor/contact-us/contact" v-text="'Email'")
              a.mr-0.grey--text.text--darken-3(href="#/contributor/faq" v-text="'FAQ`S'")
          v-col(cols="3")
            v-row
              v-col(cols="6" v-for="(store, i) in stores")
                v-img(:src="store.img" height="30")
            h3 LET`S GET SOCIAL
            v-row(no-gutters)
              v-col(cols="2" v-for="(item, i) in socialNetworks" :key="i").pa-0
                v-btn.ma-2(v-if="item.icon" fab target="_blank" small color="success" icon="" :href="item.url")
                  v-icon(size="40") {{item.icon}}
        v-row(no-gutters).d-flex.align-center
          v-col.footer-core__copyright(cols="12" md="auto")
            p.font-weight-light
              span &copy; SCENE FINDER
              a.grey--text.text--darken-3(href="#/contributor/terms-and-conditions" v-text="'Conditions'")
              a.grey--text.text--darken-3(href="#/contributor/terms-and-conditions" v-text="'Privacy'")
      div(v-else)
        v-list
          v-list-group(v-for="item in mobileItems" :key="item.title" :prepend-icon="item.action")
            template(v-slot:activator="")
              v-list-item-content
                v-list-item-title(v-text="item.title")
            v-list-item(v-for="subItem in item.subItems" :key="subItem.title" @click="goTo(subItem.route)")
              v-list-item-content
                v-list-item-title(v-text="subItem.title")
        v-list
          v-subheader Follow us
          v-list-item-group.px-4
            v-list-item(v-for="(item, i) in socialNetworks" :key="i" :href="item.url" target="_blank")
              v-list-item-icon
                v-icon(v-text="item.icon")
              v-list-item-content
                v-list-item-title(v-text="item.title")
</template>

<script>
  import appStore from '@/assets/icons/app_store.png'
  import playStore from '@/assets/icons/play-store.png'
  export default {
    name: 'DashboardCoreFooter',

    components: {
      login: () => import('@/views/pages/Login'),
      register: () => import('@/views/pages/Register'),
    },

    data: () => ({
      loginDialog: false,
      registerDialog: false,
      socialNetworks: [
        {
          icon: 'fab fa-facebook',
          title: 'Facebook',
          url: 'https://www.facebook.com/SceneFinder/',
        },
        {
          icon: 'fab fa-youtube',
          title: 'Youtube',
          url: 'https://www.youtube.com/channel/UCSkN8c4SJO1uyBHfZbQnxuQ?view_as=subscriber',
        },
        {
          icon: 'fab fa-instagram',
          title: 'Instagram',
          url: 'https://www.instagram.com/scenefinderofficial/',
        },
        {
          icon: 'fab fa-twitter',
          title: 'Twitter',
          url: 'https://twitter.com/scenefinderoff1',
        },
        {
          icon: 'fab fa-tiktok',
          title: 'TikTok',
          url: 'https://vm.tiktok.com/nM8HYv/',
        },
        {
          icon: null,
          title: null,
          url: null,
        },
      ],
      stores: [
        {
          title: 'App store',
          img: appStore,
        },
        {
          title: 'Play store',
          img: playStore,
        },
      ],
      mobileItems: [
        {
          title: 'GET TO KNOW US',
          subItems: [
            { title: 'About', route: '/contributor/about-us' },
            { title: 'Values', route: '/contributor/values' },
            { title: 'Careers', route: '/contributor/careers' },
            { title: 'Mission statement', route: '/contributor/mission' },
            { title: 'News', route: '/contributor/news' },
          ],
        },
        {
          title: 'OUR POLICIES',
          subItems: [
            { title: 'Privacy policy', route: '/contributor/terms-and-conditions' },
            { title: 'Terms and conditions', route: '/contributor/terms-and-conditions' },
            { title: 'Authorization to release', route: '/contributor/terms-and-conditions' },
            { title: 'Refund Policy', route: '/contributor/terms-and-conditions' },
          ],
        },
        {
          title: 'CONTACT US',
          subItems: [
            { title: 'Email', route: '/contributor/contact-us/contact' },
            { title: 'FAQ`S', route: '/contributor/faq' },
          ],
        },
      ],
    }),

    computed: {
      user () {
        return this.$store.state.users.user
      },
      isMobile () {
        return window.innerWidth < 1024
      },
    },

    methods: {
      switchDialog (to) {
        const vm = this
        switch (to) {
          case 'login':
            vm.registerDialog = false
            vm.loginDialog = true
            break
          case 'register':
            vm.loginDialog = false
            vm.registerDialog = true
            break
        }
      },
      openLoginDialog () {
        this.loginDialog = true
      },
      openRegisterDialog () {
        this.registerDialog = true
      },
      goTo (route) {
        this.$router.replace(route)
      },
    },
  }
</script>

<style lang="scss">
  #dashboard-core-footer{
    margin: auto auto 0;
    max-width: 1024px;
    a {
      text-decoration: none;
      padding: 0;
    }
  }
</style>
